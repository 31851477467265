import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BigTitle from "../../Components/BigTitle";
import Meta from "../../Components/Meta";
import * as dataSupport from "../../Data/TechnicalSupport.json";
import "./TechnicalSupport.scss";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount";
import Breadcrumb from "../../Components/Breadcrumb";
import SideBar from "../../Components/SideBar/SideBar";
import Footer from "../../Components/Footer";
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

const MAX_RETRIES = 5; // Nombre maximum de tentatives
const INITIAL_DELAY = 1000; // Délai initial en millisecondes (1 seconde)

const fetchDataWithRetry = async (url, retries = MAX_RETRIES, delay = INITIAL_DELAY) => {
  try {
    // Essaye d'exécuter la requête
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(`Erreur lors de la requête (tentative restante: ${retries}):`, error);

    if (retries === 0) {
      // Si on atteint 0 tentatives restantes, on jette l'erreur
      throw new Error('Echec après plusieurs tentatives.');
    }

    // Sinon, on attend un certain temps et on réessaie
    await new Promise(resolve => setTimeout(resolve, delay));

    // Augmenter le délai de manière exponentielle
    return fetchDataWithRetry(url, retries - 1, delay * 2);
  }
};

export const TechnicalSupport = () => {
  const { lang } = useParams();
  const [device, setDevice] = useState({
    activation: "0",
    code: "",
    copy: "0",
    date_purchase: "",
    device_id: "",
    file: "",
    is_validate: "0",
    name: "PHYSIO",
    name_distributer: "",
    serial_num: "",
    user_id: "",
    warranty_date: "0000-00-00",
    interface: "",
    core: ""
  });
  
  const [data, setData] = useState(dataSupport.default[lang]);
  const [meta, setMeta] = useState({
    title: dataSupport.default[lang].meta.title,
    description: dataSupport.default[lang].meta.description,
    canonical: dataSupport.default[lang].meta.canonical,
    image: dataSupport.default[lang].meta.image,
    meta: {
      charset: "utf-8",
      name: {
        keywords: dataSupport.default[lang].meta.keywords,
      },
    },
  });

  let auth = JSON.parse(sessionStorage.getItem('userData'));
  const user_id = auth.user_id;

  const hostName = "/Back-end/getDeviceById";

  const [versionInterface, setVersionInterface] = useState(null);
  const [versionCore, setVersionCore] = useState(null);
  const [error, setError] = useState(null);

  const [versionHistory, setVersionHistory] = useState([]);

  const [basicModal, setBasicModal] = useState(false);

  const toggleShow = () => {
    setBasicModal(!basicModal);
  };


  const loadHistory = async (type) => {
    if (device.device_id !== "" && (type === "IHM" || type === "CORE")) {
      const params = {
        model: device.name,
        type: type,
        value: type === "IHM" ? device.interface : device.core,
      };
      console.log(params);
      try {
        axios
          .post("/Back-end/getVersionHistory", params )
          .then((response) => {
            console.log(response.data);
            setVersionHistory(response.data.result); // Stocker les données de l'historique des versions
          })
          .catch(error => {
            console.error('Erreur lors de la récupération des versions:', error);
          });
      }catch (err) {
        setError(err.message);
      }
    }
    toggleShow();
  }

  // Fonction de récupération des données PHYSIO avec gestion d'erreurs
  useEffect(() => {
    const loadVersions = async () => {
      try {
        const devID = JSON.parse(sessionStorage.getItem("deviceID"));
        const versionData = await fetchDataWithRetry('/Back-end/getVersion/PHYSIO');
        const deviceData = await fetchDataWithRetry(`${hostName}/${devID}`);
        setVersionInterface(versionData.ihm);
        setVersionCore(versionData.core);
        setDevice(deviceData);

        // console.log(deviceData);
        // console.log(versionData);

        // Comparer avec les versions récupérées
        if (deviceData.interface === versionData.ihm) {
          document.getElementById('ihmInfo').classList.add('hidden');
          document.getElementById('ihmBtn').classList.add('hidden');
        } else {
          document.getElementById('ihm').classList.add('flash');
        }

        if (deviceData.core === versionData.core) {
          document.getElementById('coreInfo').classList.add('hidden');
          document.getElementById('coreBtn').classList.add('hidden');
          document.getElementById('coreUpdateBtn').classList.add('hidden');
        } else {
          document.getElementById('core').classList.add('flash');
        }
      } catch (err) {
        setError(err.message);
      }
    };

    loadVersions();
  }, [user_id]); // Dépendance sur user_id pour n'exécuter qu'une fois

  useEffect(() => {
    setData(dataSupport.default[lang]);
    setMeta({
      title: dataSupport.default[lang].meta.title,
      description: dataSupport.default[lang].meta.description,
      canonical: dataSupport.default[lang].meta.canonical,
      keywords: dataSupport.default[lang].meta.keywords,
      image: dataSupport.default[lang].meta.image,
    });
  }, [lang]);

  const sendUpdate = () => {
    const params = {
      device: device,
      mail: data.mail,
      auth: auth,
      lang: lang,
      version: versionCore
    };
    try {
      axios
        .post("/Back-end/sendCoreVersion", params )
        .then((response) => {
          console.log(response.data);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des versions:', error);
        });
    }catch (err) {
      setError(err.message);
    }
  }

  return (
    <>
      <Meta meta={meta} />
      <HeaderAccount />
      <Breadcrumb title={data.breadcrumbPHYSIO} />
      <SideBar >
        <section className="technicalSupport">
          {/* Affichage des informations sur le device */}
          <div className="toolbox">
            <BigTitle title="" span={data.device.title} direct="left" />
            <p className='pinfo'>{data.device.numSerial} {device.serial_num}</p>
            <hr />
            <p className='pinfo'>{data.device.codeAct} {device.code}</p>
            <hr />
          </div>

          {/* Affichage des versions */}
          <div className="toolbox">
            <BigTitle title="" span={data.information.title} direct="left" />
            <p id="ihm" className='pinfo'>
              {data.information.interface} {device.interface}
              <span id="ihmInfo" className="notif">
                {data.information.updateInfo}
              </span>
            </p>
            <div id="ihmBtn" className="buttonExtended color" onClick={() => loadHistory("IHM")}>
                  <span className="hyphen"></span>
                  <div className="buttonExtended_text ligne-texte-icone">
                    <span className="texte">{data.information.about}</span>
                  </div>
              </div>
            <hr />
            <p id="core" className='pinfo'>
              {data.information.core} {device.core}
              <span id="coreInfo" className="notif">
                {data.information.updateInfo}
              </span>
              <br></br>
              <div id="coreUpdateBtn" className="buttonExtended color" onClick={() => sendUpdate()}>
                <span className="hyphen"></span>
                <div className="buttonExtended_text ligne-texte-icone">
                  <span className="texte">{data.information.request}</span>
                </div>
              </div>
              <div id="coreBtn" className="buttonExtended right color" onClick={() => loadHistory("CORE")}>
                <span className="hyphen"></span>
                <div className="buttonExtended_text ligne-texte-icone">
                  <span className="texte">{data.information.about}</span>
                </div>
              </div>
            </p>
            <hr />
          </div>

          <div className="toolbox">  {/*documentation*/}
            <BigTitle
              text=""
              title=""
              span={data.download.title}
              direct="left"
            />

            <p className='pinfo'>{data.download.userguide}</p>
            <p className='pinfo'>Français   <a
              className="center"
              href={`https://mad-up.fr/guide/PHYSIO/Guide\ utilisateur.pdf?download`}
              target="_blank"
              download="guide utilisateur MAD-UP PHYSIO.pdf"
            >
              <IconButton aria-label="download">
                <i className="fa-solid fa-download"></i>
              </IconButton>
            </a></p>
            <hr />
            <p className='pinfo'>English    <a
              className="center"
              href={`https://mad-up.fr/guide/PHYSIO/User\ manual.pdf?download`}
              target="_blank"
              download="MAD-UP PHYSIO user guide.pdf"
            >
              <IconButton aria-label="download">
                <i className="fa-solid fa-download"></i>
              </IconButton>
            </a></p>
            <hr />
          </div>

          {/* Modal */}
          <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <h5>{data.history.title}</h5>
                  {versionHistory.length > 0 ? (
                    <ul>
                      <hr />
                      {versionHistory.map((version, index) => (
                        <li key={index} className="version-item">
                          <p>{data.history.version} <strong>{version.value}</strong> {data.history.edit} {new Date(version.date).toLocaleDateString()}</p>
                          <p>{data.history.details}</p>
                          <TextWithLineBreaks content={version.description} />
                          <hr />
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>{data.history.no_version}</p>
                  )}
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn style={{ background: "#DC3535" }} onClick={toggleShow}>{data.history.close}</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </section>
      </SideBar>
      <Footer />
    </>
  );
};

const TextWithLineBreaks = ({ content }) => {
  return (
    <div style={{ whiteSpace: 'pre-line' }}>
      {content}
    </div>
  );
};

export default TechnicalSupport;
